import {Route, Routes} from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { LoginPage } from './pages/LoginPage'
import { MyProfilePage } from './pages/MyProfilePage'
import { PublicProfilePage } from './pages/PublicProfilePage'
import { RegistrationPage } from './pages/RegistrationPage'
import { MessagePage } from './pages/MessagePage'
import { TaskPage } from './pages/TaskPage'
import { MissionPage } from './pages/MissionPage'
import { ReviewPage } from './pages/ReviewPage'
import { QrGenPage } from './pages/QrGenPage'
import { ChatroomPage } from './pages/ChatroomPage'
import { ProfileWallPage } from './pages/ProfileWallPage'
import { LandingPage } from './pages/LandingPage'
import { MarketPage } from './pages/MarketPage'
import { PrivateLayout } from './layouts/PrivateLayout'
import { BottomNavigationLayout } from './layouts/BottomNavigationLayout'
import { AllProfilesPage } from './pages/AllProfilesPage'
import { PhotoGridPage } from './pages/PhotoGridPage'
import { PublicChatPage } from './pages/PublicChatPage'
import { PaddingLayout } from './layouts/PaddingLayout'
import { GuestbookPage } from './pages/GuestbookPage'
import { NotificationPage } from './pages/NotificationPage'
import { AlbumListPage } from './pages/AlbumListPage';
import { AlbumManagementPage } from './pages/AlbumManagementPage'
import { AlbumPage } from './pages/AlbumPage'
import { NotePage } from './pages/NotePage'
import { AiRoomPage } from './pages/AiRoomPage'

export const RouteTable = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path=":projectCode" element={<PrivateLayout />}>
        <Route element={<PaddingLayout />}>
          <Route path="home" element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="profile" element={<MyProfilePage />} />
          <Route path="message" element={<MessagePage />} />
          <Route path="guestbook" element={<GuestbookPage />} />
          <Route path="notification" element={<NotificationPage />} />
          <Route path="task/:tab" element={<TaskPage />} />
          <Route path="mission/:idMission" element={<MissionPage />} />
          <Route path="review/:idMission" element={<ReviewPage />} />
          <Route path="qr" element={<QrGenPage />} />
          <Route path="s" element={<PublicProfilePage />} />
          <Route path="share/:simpleCode" element={<PublicProfilePage />} />
          <Route path="registration" element={<RegistrationPage />} />
          <Route path="chatroom/:connectionId" element={<ChatroomPage />} />
          <Route path="albums" element={<AlbumListPage />} />
          <Route
            path="albums/:idAlbum/edit"
            element={<AlbumManagementPage />}
          />
          <Route path="albums/:idAlbum" element={<AlbumPage />} />
          <Route
            path="chatroom/:fromMemberNo/:toMemberNo"
            element={<ChatroomPage />}
          />
          <Route
            path="chatroom/:fromMemberNo/:toMemberNo/:connectionId"
            element={<ChatroomPage />}
          />
          <Route path="profiles" element={<ProfileWallPage />} />
          <Route path="market/:masterMemberNo" element={<MarketPage />} />
          <Route path="ai-room" element={<AiRoomPage />} />
          <Route element={<BottomNavigationLayout />}>
            <Route path="all-profiles" element={<AllProfilesPage />} />
            <Route path="photo-grid" element={<PhotoGridPage />} />
            <Route path="notes/:idChatroomMessage" element={<NotePage />} />
          </Route>
        </Route>
        <Route path="public-chat/:connectionId" element={<PublicChatPage />} />
      </Route>
    </Routes>
  );
};