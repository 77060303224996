import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { PublicCrudApi } from '../../apis/public-crud-api';
import { IMemberActionCount } from '../../interfaces/IMemberActionCount';
import { IChartData } from '../../interfaces/IChartData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
);

interface ActionChartBlockProps {
  memberNo: string
}

const getUserTimezoneOffset = (): number => {
  return new Date().getTimezoneOffset() / -60; // Convert minutes to hours
};

const convertUtcToLocalHour = (utcHour: number, offset: number): number => {
  return (utcHour + offset + 24) % 24; // Ensure hour stays within 0-23 range
};

export const ActionChartBlock = ({memberNo}: ActionChartBlockProps) => {
  const api = PublicCrudApi('sns', 'actions')
  const [chartData, setChartData] = useState<IChartData>();
  useEffect(() => {
    api.getById<IMemberActionCount[]>(memberNo).then(data => {
      const timezoneOffset = getUserTimezoneOffset();
      const hours = Array.from({ length: 24 }, (_, i) => i);
      const actionCounts = new Array(24).fill(0);

      data.forEach((action) => {
        const localHour = convertUtcToLocalHour(
          action.actionHour,
          timezoneOffset
        );
        actionCounts[localHour] = action.actionCount;
      });

      setChartData({
        labels: hours.map((hour) => `${hour}:00`),
        datasets: [
          {
            data: actionCounts,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            borderRadius: Number.MAX_VALUE,
          },
        ],
      });
    })
  }, [memberNo])
  return (
    <>
      {chartData ? (
        <Bar
          data={chartData}
          options={{ responsive: true, scales: { y: { display: false } } }}
        />
      ) : (
        <p>Loading chart...</p>
      )}
    </>
  );
};
