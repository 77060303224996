import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Translation } from 'react-i18next';
import {
  ExternalLinkIcon,
  CheckCircleIcon,
  RepeatIcon,
  Icon,
  WarningIcon,
} from '@chakra-ui/icons';
import {
  Flex,
  Spacer,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  CloseButton,
  Center,
  Image,
  DrawerFooter,
  Avatar,
  Skeleton,
  Stack,
  useToast,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepTitle,
  Stepper,
} from '@chakra-ui/react';
import { useApi } from '../../api';
import {
  IBanner,
  ICargo,
  IChatMessage,
  IProfile,
  ISubMember,
  IUserCharacter,
} from '../../interfaces';
import { Logo } from '../../components/Logo';
import { LeaveMessageButton } from '../../components/LeaveMessageButton';
import { CharacterFlavor } from '../../components/CharacterFlavor';
import { Utils } from '../../utils';
import { t } from 'i18next';
import { PhotosWall } from '../../components/PhotosWall';
import { RouletteModal } from '../../components/RouletteModal';
import { BottomNavigation } from '../../components/BottomNavigation';
import { usePrivateApi } from '../../hooks/usePrivateApi';
import { flameAtom, profileAtom } from '../../stores/profile';
import { useRecoilState, useRecoilValue } from 'recoil';
import { StickyNote } from '../../components/StickyNote';
import { AvatorScrollRow } from '../../components/AvatorScrollRow';
import { PublicCrudApi } from '../../apis/public-crud-api';
import {
  BsInstagram,
  BsSkype,
  BsStars,
  BsTelegram,
  BsTwitter,
  BsWechat,
  BsFillGeoAltFill,
  BsFillTelephoneInboundFill,
} from 'react-icons/bs';
import { RiGlobalFill } from 'react-icons/ri';
import { IHierarchyHistory } from '../../interfaces/IHierarchyHistory';
import { SubMembersBlock } from './SubMembersBlock';
import mapboxgl from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import { IPosition } from '../../interfaces/IPosition';
import { IGeolocation } from '../../interfaces/IGeolocation';
import { createRoot } from 'react-dom/client';
import { AvatorMarker } from '../../components/AvatorMarker';
import { BlockBox } from './BlockBox';
import { HiBookmark } from 'react-icons/hi';
import { isSafeModeAtom } from '../../stores/view';
import { IDna } from '../../interfaces/IDna';
import { useMapKey } from '../../hooks/useMapKey';
import dcardIcon from '../../assets/imgs/Dcard_Favicon_x520.png';
import { ActionChartBlock } from './ActionChartBlock';
import { GPSArrow } from './GPSArrow';
import { ICoordinates } from '../../interfaces/ICoordinates';

export const PublicProfilePage = () => {
  const wallChatroomId = '42b90196b487c54069097a68fe98ab6f';
  const navigate = useNavigate();
  const { projectCode, simpleCode } = useParams();
  const api = useApi(projectCode);
  const privateApi = usePrivateApi();
  const toast = useToast();

  const publicBlockApi = PublicCrudApi('sns', 'blocks');
  const geolocationApi = PublicCrudApi('sns', 'geolocation');
  const visitorApi = PublicCrudApi('core', `profiles/related/visitors`);
  const dnaApi = PublicCrudApi('family', 'dna');

  const mapContainer = useRef<any>();
  const map = useRef<mapboxgl.Map | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [nickname, setNickname] = useState('');
  const [multiGender, setMultiGender] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [avator, setAvator] = useState<string | null>(null);
  const [listItems, setListItems] = useState<[]>();
  const [drawerTitle, setDrawerTitle] = useState('');
  const [drawerListItems, setDrawerListItems] = useState([]);
  const [hotProfiles, setHotProfiles] = useState<IProfile[]>([]);
  const [newProfiles, setNewProfiles] = useState<IProfile[]>([]);
  const [lastAccessProfiles, setLastAccessProfiles] = useState<IProfile[]>([]);
  const [lastChatMessageProfiles, setLastChatMessageProfiles] = useState<
    IProfile[]
  >([]);
  const [memberNo, setMemberNo] = useState<string>();
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [userCharacters, setUserCharacters] = useState<IUserCharacter[]>([]);
  const [sameCharacters, setSameCharacters] = useState<IUserCharacter[]>([]);
  const [match, setMatch] = useState<number>(0);
  const [showRouletteModal, setRouletteModal] = useState<boolean>(false);
  const [pubProfile, setPubProfile] = useState<IProfile>();
  const [subMembers, setSubMembers] = useState<ISubMember[]>([]);
  const [relatedMemberProfiles, setRelatedMemberProfiles] = useState<IProfile[]>([]);
  const [flame, setFlame] = useRecoilState(flameAtom);
  const [chatMsgCount, setChatMsgCount] = useState<number>(0);
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [hierarchyHistories, setHierarchyHistories] = useState<
    IHierarchyHistory[]
  >([]);
  const [currentPosition, setCurrentPosition] = useState<ICoordinates>();
  const [userGeolocation, setUserGeolocation] = useState<IGeolocation>();
  const [blockCountList, setBlockCountList] = useState<boolean[]>([]);
  const [isFollower, setIsFollower] = useState<boolean>();
  const [dna, setDna] = useState<IDna>();
  const isSafeMode = useRecoilValue(isSafeModeAtom);
  const [mapZoomIn, setMapZoomIn] = useState<number>(0);
  const myProfile = useRecoilValue(profileAtom);

  useEffect(() => {
    api.getLastFlame((cargo: ICargo) => {
      setFlame(cargo.info);
    });
  }, []);

  useEffect(() => {
    if (userCharacters.length > 0 && Utils().isLogin()) {
      privateApi.getUserCharacters((cargo: ICargo) => {
        const myUserCharacters: IUserCharacter[] = cargo.info;
        const same = userCharacters.filter((character) =>
          myUserCharacters.some(
            (myUserCharacter) =>
              character.character.idCharacter ===
              myUserCharacter.character.idCharacter
          )
        );
        setSameCharacters(same);
        setMatch(
          same.length /
            (userCharacters.length + myUserCharacters.length - same.length)
        );
      });
    }
  }, [userCharacters]);

  const fetchPubProfile = useCallback(
    (shareCode: string) => {
      api.getPublicProfile(shareCode, (cargo: ICargo) => {
        if (cargo.returnCode === '000000') {
          const publicProfile: IProfile = cargo.info;
          setPubProfile(publicProfile);
          switch (publicProfile.multiGender) {
            case '1':
              setMultiGender('maleS');
              break;
            case '2':
              setMultiGender('femaleS');
              break;
            case '3':
              setMultiGender('maleM');
              break;
            case '4':
              setMultiGender('femaleM');
              break;
            case '5':
              setMultiGender('maleSW');
              break;
            case '6':
              setMultiGender('femaleSW');
              break;
            default:
              break;
          }
          setNickname(publicProfile.nickname || '');
          if (cargo.info['introduction']) {
            setIntroduction(cargo.info['introduction']);
          }
          if (cargo.info['avator']) {
            const path = Utils().normalizeImagePath(
              cargo.info['avator'],
              projectCode
            );
            setAvator(path ? path : null);
          } else {
            const gravatar = `https://gravatar.com/avatar/${publicProfile.emailHash}?s=128&d=404`;
            fetch(gravatar)
              .then((response) => {
                if (response.status === 200) {
                  return response.blob();
                }
              })
              .then((blob) => {
                if (blob) {
                  setAvator(URL.createObjectURL(blob));
                }
              });
          }
          setMemberNo(cargo.info['simpleCode']);
        }
      });
    },
    [api]
  );

  const fetchHotProfile = useCallback(() => {
    if (!projectCode) return;
    api.getHotProfile(projectCode, 20, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setHotProfiles(cargo.info);
      }
    });
  }, [api]);

  const fetchNewProfile = useCallback(() => {
    if (!projectCode) return;
    api.getNewProfiles(projectCode, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setNewProfiles(cargo.info);
      }
    });
  }, [api]);

  const fetchLastAccessProfiles = useCallback(() => {
    if (!projectCode) return;
    api.getLastAccessProfiles(projectCode, 30, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        const profiles: IProfile[] = cargo.info;

        setLastAccessProfiles(
          profiles.filter(
            (p) =>
              p.simpleCode !== window.localStorage.getItem('memberNo') &&
              !p.blur
          )
        );
      }
    });
  }, [api]);

  const fetchLastChatMessageProfiles = useCallback(() => {
    if (!projectCode) return;
    api.getLastChatMessageProfiles(projectCode, 30, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        const profiles: IProfile[] = cargo.info;
        setLastChatMessageProfiles(profiles.filter((p) => !p.blur));
      }
    });
  }, [api]);

  const fetchSubMembers = useCallback(() => {
    if (!memberNo) return;
    api.getSubMembers(memberNo, (cargo: ICargo) => {
      setSubMembers(cargo.info['subNodes']);
    });
  }, [api]);

  const fetchBlockCount = useCallback(() => {
    if (!memberNo) return;
    publicBlockApi.countById(memberNo).then((data) => {
      const countList = [];
      for (let i = 0; i < 10; ++i) {
        if (i < Number(data)) {
          countList.push(true);
        } else {
          countList.push(false);
        }
      }
      setBlockCountList(countList);
    });
  }, [publicBlockApi]);

  const buildDrawerItems = (data: any) => {
    return data.map(
      (userCharacter: any) =>
        userCharacter['nickname'] !== null &&
        userCharacter['nickname'].trim().length > 0 && (
          <div
            key={userCharacter['idUserCharacter']}
            className="mb-3 p-3 shadow-inner rounded-lg cursor-pointer"
            onClick={() => {
              window.location.href = `s?c=${userCharacter['simpleCode']}`;
            }}
          >
            <Flex>
              <div>
                <Image
                  src={
                    userCharacter['avator'] === null
                      ? `https://bdsmer.club/files/${projectCode}/logo.jpg`
                      : Utils().normalizeImagePath(
                          userCharacter['avator'],
                          projectCode
                        )
                  }
                  borderRadius={'md'}
                  width="12"
                  className={
                    userCharacter['avator'] && !Utils().isLogin() && 'blur-sm'
                  }
                />
              </div>
              <div className="ml-3">{userCharacter['nickname']}</div>
              <Spacer></Spacer>
              <div className="pt-1">
                <ExternalLinkIcon />
              </div>
            </Flex>
          </div>
        )
    );
  }

  const fetchCharacterUsers = useCallback(
    (idCharacter: Number) => {
      api.getCharacterUsers(idCharacter, (cargo: ICargo) => {
        if (cargo.returnCode === '000000') {
          setDrawerListItems(buildDrawerItems(cargo.info));
        }
      });
    },
    [api]
  );

  useEffect(() => {
    if (mapZoomIn % 10 === 0) {
      map.current?.zoomIn()
    }
  }, [mapZoomIn])

  useEffect(() => {
    let url = new URL(window.location.href);
    let shareCode =
      url.searchParams.get('c') ||
      url.searchParams.get('sharingCode') ||
      simpleCode ||
      '';
    if (!shareCode) {
      navigate(`/${projectCode}/profiles`)
    }
    fetchPubProfile(shareCode);
    fetchHotProfile();
    fetchNewProfile();
    fetchLastAccessProfiles();
    fetchLastChatMessageProfiles();
    api.getCharacters((cargo: ICargo) => {
      let characterList: any = [];
      let characters = cargo.info;

      api.getUserPublicCharacters(shareCode, (cargo: ICargo) => {
        if (!cargo.success) return;
        let userCharacters = cargo.info;
        setUserCharacters(userCharacters);
        for (let character of characters) {
          character['checked'] = false;
          for (let userCharacter of userCharacters) {
            if (userCharacter['idCharacter'] === character['idCharacter']) {
              character['checked'] = true;
              break;
            }
          }
          characterList.push(character);
        }
        const items = characterList.map((character: any) => (
          <div
            key={character['idCharacter']}
            onClick={() => {
              setDrawerTitle(t(character['title']));
              onOpen();
              fetchCharacterUsers(character['idCharacter']);
            }}
          >
            <CharacterFlavor character={character}></CharacterFlavor>
          </div>
        ));
        setListItems(items);
      });
      api.visit(shareCode, (cargo: ICargo) => {});
    });
    window.scrollTo({ top: 0 });
  }, [simpleCode]);

  useEffect(() => {
    if (!memberNo) return;
    api.getUserBanners(memberNo, (cargo: ICargo) => {
      if (cargo.success) {
        setBanners(cargo.info);
      }
    });
    fetchSubMembers();
    fetchBlockCount();
    api.getChatMessageCount(memberNo, (cargo: ICargo) => {
      if (cargo.success) {
        setChatMsgCount(cargo.info);
      }
    });
    api.getChatMessages(wallChatroomId, memberNo, (cargo: ICargo) => {
      if (cargo.success) {
        setMessages(cargo.info.reverse());
      }
    });
    api.getHierarchyHistory(memberNo, (cargo: ICargo) => {
      setHierarchyHistories(cargo.info);
    });
    geolocationApi
      .getById(memberNo)
      .then((data) => {
        setUserGeolocation(data as IGeolocation);
      })
      .catch(() => {
        setUserGeolocation(undefined);
      });
    if (Utils().isLogin()) {
      privateApi.isFollowMember(memberNo, (cargo: ICargo) => {
        setIsFollower(cargo.info);
      });
    }
    visitorApi.getById(memberNo).then((data) => {
      setRelatedMemberProfiles(data as IProfile[]);
    });
    dnaApi.getById(memberNo).then((data) => {
      setDna(data as IDna);
    })
  }, [memberNo]);

  useEffect(() => {
    if (!userGeolocation) return;
    if (!map.current) {
      mapboxgl.accessToken = useMapKey();
      map.current = new mapboxgl.Map({
        container: mapContainer.current, // container ID
        style: 'mapbox://styles/mapbox/light-v11', // style URL
        center: [
          Number(userGeolocation.longitude),
          Number(userGeolocation.latitude),
        ], // starting position [lng, lat]
        zoom: 6, // starting zoom
        interactive: false,
      });
    }
    let makers = document.getElementsByClassName('marker');
    while (makers.length > 0) {
      for (let i = 0; i < makers.length; ++i) {
        makers[i].remove();
      }
      makers = document.getElementsByClassName('marker');
    }
    
    const avatorPath = Utils().avatorUrlBuilder(pubProfile);
    if (avatorPath) {
      const size = 48;
      const el = document.createElement('div');
      el.className = 'marker';
      const marker = createRoot(el);
      marker.render(
        <AvatorMarker
          size={size}
          path={avatorPath}
          alt={pubProfile?.nickname}
          onClick={() => {
            setMapZoomIn(mapZoomIn => mapZoomIn + 1)
          }}
        />
      );
      new mapboxgl.Marker(el)
        .setLngLat([
          Number(userGeolocation.longitude),
          Number(userGeolocation.latitude),
        ])
        .addTo(map.current);
      map.current?.flyTo({
        center: [
          Number(userGeolocation.longitude),
          Number(userGeolocation.latitude),
        ]
      });
    }
  }, [userGeolocation]);

  useEffect(() => {
    if (!myProfile?.simpleCode) return 
    geolocationApi.getById<IGeolocation>(myProfile?.simpleCode).then(data => {
      if (!data.latitude || !data.longitude) return
      setCurrentPosition({
        latitude: data.latitude,
        longitude: data.longitude
      })
    })
  }, [myProfile])

  useEffect(() => {
    const url = new URL(window.location.href);
    const showRoulette = new URLSearchParams(url.searchParams).get(
      'showRoulette'
    );
    setRouletteModal(showRoulette === 'true');
  }, []);

  return (
    <>
      {pubProfile?.closed && (
        <div className="rounded-full bg-yellow-500 text-center py-1">
          {t('profileClosed')}
        </div>
      )}
      <div className={`${pubProfile?.closed ? 'blur' : ''}`}>
        <Flex
          className={`rounded-t-xl shadow ${
            pubProfile?.isRainbow ? 'rainbow' : ''
          }`}
        >
          <Box maxW="36">
            <h1 className="text-3xl font-bold">
              {avator === null ? (
                <Logo></Logo>
              ) : (
                <Center className="m-5">
                  <Box
                    onClick={() => {
                      // window.open(
                      //   `https://www.google.com/search?udm=2&q=${Utils().avatorUrlBuilder(
                      //     pubProfile
                      //   )}`,
                      //   '_blank'
                      // );
                    }}
                  >
                    <Image
                      borderRadius={'2xl'}
                      src={Utils().avatorUrlBuilder(pubProfile)}
                      shadow={'dark-lg'}
                      alt={`${pubProfile?.idProfile}`}
                      className={`${pubProfile?.blur ? 'blur-sm' : ''} w-full`}
                    />
                    {/* <div
                    className={`w-28 h-28 bg-cover bg-center rounded-2xl shadow-gray-700 shadow cursor-pointer ${pubProfile?.blur ? 'blur-sm' : ''}`}
                    style={{
                      backgroundImage: `url(${Utils().avatorUrlBuilder(
                        pubProfile
                      )})`,
                    }}
                  ></div> */}
                    {pubProfile?.kycStatus === 1 && (
                      <CheckCircleIcon
                        color={'green.400'}
                        className="absolute top-5 left-5 bg-white rounded-full"
                      />
                    )}
                    {pubProfile?.unqualifiedCount &&
                    pubProfile?.unqualifiedCount > 10 ? (
                      <WarningIcon
                        color={'yellow.400'}
                        className="absolute top-5 left-5 bg-white rounded-full"
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                </Center>
              )}
            </h1>
          </Box>
          <Spacer />
          <Box p="4">
            {flame?.memberNo === window.localStorage.getItem('memberNo') ? (
              <Button
                bgColor={'white'}
                rightIcon={<RepeatIcon />}
                colorScheme="teal"
                variant="outline"
                onClick={() => {
                  if (!memberNo) return;
                  privateApi.deliveryFlame(memberNo, (cargo: ICargo) => {
                    if (cargo.success) {
                      setFlame(null);
                    }
                  });
                }}
              >
                {t('torchRelays')}
              </Button>
            ) : (
              isFollower !== undefined && (
                <span className="cursor-pointer ">
                  <HiBookmark
                    className={`${
                      isFollower ? 'text-red-600' : 'text-gray-300'
                    }`}
                    size={20}
                    onClick={() => {
                      if (!memberNo) return;
                      if (isFollower) {
                        setIsFollower(false);
                        privateApi.unFollowMember(memberNo, (cargo: ICargo) => {
                          if (!cargo.success) {
                            setIsFollower(true);
                          }
                        });
                      } else {
                        setIsFollower(true);
                        privateApi.followMember(memberNo, (cargo: ICargo) => {
                          if (!cargo.success) {
                            setIsFollower(false);
                          }
                        });
                      }
                    }}
                  />
                </span>
              )
            )}
          </Box>
        </Flex>
        <div className="grid gap-3 grid-cols-1 md:grid-cols-2">
          <div>
            <div className="py-2 shadow rounded-b-xl">
              <Flex>
                <div className="px-8 pt-6 pb-5 flex flex-col">
                  <div>{nickname}</div>
                  <div className="text-xs text-slate-600">
                    <Translation>{(t) => t(multiGender)}</Translation>
                  </div>
                </div>
                <Spacer />
                <LeaveMessageButton></LeaveMessageButton>
              </Flex>
            </div>
            {!Utils().isLogin() && (
              <div
                className="p-2"
                onClick={() => {
                  window.location.href = `/${projectCode}/login`;
                }}
              >
                登入後可瀏覽完整圖片
              </div>
            )}
            {introduction.length > 0 ? (
              <div className="px-5 py-5 my-5 shadow rounded-xl whitespace-pre-wrap">
                <div className="font-bold">
                  <Translation>{(t) => t('introduction')}</Translation>
                </div>
                <Divider></Divider>
                <div className="mt-5">{introduction}</div>
                <Divider className="mt-5 mb-3"></Divider>
                {dna && dna.zodiacSign && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsStars} className="m-1" />
                    <span className="text-sm">
                      {t(`${dna.zodiacSign.toLowerCase()}`)}
                    </span>
                  </div>
                )}
                {pubProfile?.websiteUrl && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={RiGlobalFill} className="m-1" />
                    <span className="underline underline-offset-4 text-sm">
                      <a href={pubProfile.websiteUrl}>{pubProfile.nickname}</a>
                    </span>
                  </div>
                )}
                {pubProfile?.skypeId && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsSkype} className="m-1" />
                    <span className="text-sm">{pubProfile.skypeId}</span>
                  </div>
                )}
                {pubProfile?.wechatId && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsWechat} className="m-1" />
                    <span className="text-sm">{pubProfile.wechatId}</span>
                  </div>
                )}
                {pubProfile?.telegramId && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsTelegram} className="m-1" />
                    <span className="text-sm">{pubProfile.telegramId}</span>
                  </div>
                )}
                {pubProfile?.twitterId && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsTwitter} className="m-1" />
                    <span className="text-sm">{pubProfile.twitterId}</span>
                  </div>
                )}
                {pubProfile?.instagramId && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsInstagram} className="m-1" />
                    <span className="text-sm">{pubProfile.instagramId}</span>
                  </div>
                )}
                {pubProfile?.dcardId && (
                  <div className="flex items-center text-gray-500">
                    <img src={dcardIcon} width={15} className="m-1" />
                    <span className="text-sm underline underline-offset-2">
                      <a
                        href={`https://www.dcard.tw/@${pubProfile.dcardId.replace(
                          '@',
                          ''
                        )}`}
                      >
                        {`@${pubProfile.dcardId.replace('@', '')}`}
                      </a>
                    </span>
                  </div>
                )}
                {pubProfile?.defaultAddress && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsFillGeoAltFill} className="m-1" />
                    <span className="text-sm">{pubProfile.defaultAddress}</span>
                  </div>
                )}
                {pubProfile?.phone && (
                  <div className="flex items-center text-gray-500">
                    <Icon as={BsFillTelephoneInboundFill} className="m-1" />
                    <span className="text-sm">{pubProfile.phone}</span>
                  </div>
                )}
              </div>
            ) : (
              <div className="px-5 py-5 my-5 shadow">
                <Stack>
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                  <Skeleton height="20px" />
                </Stack>
              </div>
            )}
            <RouletteModal
              userCharacters={userCharacters}
              open={showRouletteModal}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
            <div className="grid gap-3 grid-cols-2">
              <Button
                className="w-full rounded-b-xl"
                colorScheme="white"
                textColor={'black'}
                shadow={'lg'}
                rounded={'xl'}
                onClick={() => {
                  const visitorMemberNo =
                    window.localStorage.getItem('memberNo');
                  if (!memberNo || !visitorMemberNo) return;

                  navigate(
                    `/${projectCode}/chatroom/${visitorMemberNo}/${memberNo}`
                  );
                }}
              >
                {t('privateChatroom')}
              </Button>
              <Button
                className="w-full rounded-xl"
                colorScheme="white"
                textColor={'black'}
                shadow={'lg'}
                rounded={'xl'}
                onClick={() => {
                  if (!memberNo) return;
                  navigate(`/${projectCode}/chatroom/${Utils().md5(memberNo)}`);
                }}
              >
                {t('personalChatroom')}
              </Button>
            </div>

            {pubProfile &&
              (pubProfile.multiGender === '1' ||
                pubProfile.multiGender === '2') && (
                <SubMembersBlock
                  masterProfile={pubProfile}
                  subMembers={subMembers}
                  onBeSubMember={fetchSubMembers}
                />
              )}
            {hierarchyHistories.length > 0 && (
              <div className={`my-5 py-3 px-5 rounded-xl shadow-lg`}>
                <div className="mt-2 mb-5">{t('footprints')}</div>
                <Stepper
                  index={hierarchyHistories.length}
                  orientation="vertical"
                  height={`${hierarchyHistories.length * 60}px`}
                  gap="0"
                >
                  {hierarchyHistories
                    .sort((a, b) => a.idHierarchyHistory - b.idHierarchyHistory)
                    .map((step, index) => (
                      <Step key={index}>
                        <StepIndicator>
                          <Avatar
                            className="cursor-pointer"
                            size={'sm'}
                            src={Utils().normalizeImagePath(
                              step.parentProfile.avator,
                              step.parentProfile.projectCode
                            )}
                            onClick={() => {
                              navigate(
                                `/${projectCode}/share/${step.parentProfile.simpleCode}`
                              );
                            }}
                          />
                        </StepIndicator>

                        <Box flexShrink="0" marginTop={-2}>
                          <StepTitle>{step.parentProfile.nickname}</StepTitle>
                          <StepDescription>
                            <span className="text-xs">
                              {Utils().datetimeFormatter(step.dateAdd)}
                            </span>
                          </StepDescription>
                        </Box>

                        <StepSeparator />
                      </Step>
                    ))}
                </Stepper>
                <div className="text-right text-sm text-stone-600">{`${nickname} ${t(
                  'hasBeenFollowed'
                )} ${hierarchyHistories.length} ${t('masterMistress')}`}</div>
              </div>
            )}
            <div className="my-5 p-5 shadow-lg rounded-xl">
              {match > 0 && (
                <div className="float-right">
                  <span className="bg-red-100 text-red-800 text-xs font-medium px-3 py-1 rounded dark:bg-red-900 dark:text-red-300">
                    {t('matchRate')} {Math.round(match * 100)}%
                  </span>
                </div>
              )}
              <div className="my-3">
                {nickname}
                {t('sFavor')}
              </div>
              {userCharacters.map((character) => {
                return (
                  <span
                    key={character.character.idCharacter}
                    className="bg-fuchsia-100 text-fuchsia-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-fuchsia-900 dark:text-fuchsia-300 inline-block cursor-pointer"
                    onClick={() => {
                      setDrawerTitle(t(character.character.title));
                      onOpen();
                      fetchCharacterUsers(character.character.idCharacter);
                    }}
                  >
                    {t(character.character.title)}
                  </span>
                );
              })}
              {sameCharacters.length > 0 && (
                <div>
                  <Divider className="mt-4" />
                  <div className="my-3">{t('matchedFavor')}</div>
                </div>
              )}
              {sameCharacters.map((character) => {
                return (
                  <span
                    key={character.character.idCharacter}
                    className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 inline-block cursor-pointer"
                    onClick={() => {
                      setDrawerTitle(t(character.character.title));
                      onOpen();
                      fetchCharacterUsers(character.character.idCharacter);
                    }}
                  >
                    {t(character.character.title)}
                  </span>
                );
              })}
            </div>
            {userGeolocation && (
              <div className="my-5 p-2 shadow-lg rounded-xl">
                <div
                  ref={mapContainer}
                  className="map-container"
                  style={{ width: '100%', height: '25vh', borderRadius: '5px' }}
                />
              </div>
            )}
            {userGeolocation &&
              userGeolocation.latitude &&
              userGeolocation.longitude &&
              currentPosition && (
                <div className="mb-5 px-2 pb-2 shadow-lg rounded-xl">
                  <GPSArrow
                    userLocation={{
                      latitude: currentPosition.latitude,
                      longitude: currentPosition.longitude,
                    }}
                    targetLocation={{
                      latitude: userGeolocation.latitude,
                      longitude: userGeolocation.longitude,
                    }}
                  />
                </div>
              )}
            {memberNo && (
              <div className="px-5 py-5 my-5 shadow rounded-xl">
                <div className="mb-3">{t('activeTime')}</div>
                <ActionChartBlock memberNo={memberNo} />
              </div>
            )}
            <div>
              <div className="gap-3 columns-1 lg:columns-1">
                <div className="w-full inline-block mb-3">
                  {messages.map((message, index) => {
                    const profile = message.profile;
                    if (!profile) return null;
                    return (
                      <StickyNote
                        key={`m_${message.idMessage}_${index}`}
                        profile={profile}
                        content={message.message}
                        timestamp={message.dateAdd}
                        replyId={message.idChatroomMessage}
                        replyCount={message.threadCount}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="px-5 py-5 my-5 shadow rounded-xl">
              <div className="mb-3">{t('alsoVisited')}</div>
              <AvatorScrollRow profiles={relatedMemberProfiles} />
            </div>
            <div className="px-5 py-5 my-5 shadow rounded-xl">
              <div className="mb-3">{t('hotProfile')}</div>
              <AvatorScrollRow profiles={hotProfiles} />
            </div>
            <div className="px-5 py-5 my-5 shadow rounded-xl">
              <div className="mb-3">{t('newbie')}</div>
              <AvatorScrollRow profiles={newProfiles} />
            </div>
            <div className="px-5 py-5 my-5 shadow rounded-xl">
              <div className="mb-3">{t('latestOnline')}</div>
              <AvatorScrollRow profiles={lastAccessProfiles} />
            </div>
            <div className="px-5 py-5 my-5 shadow rounded-xl">
              <div className="mb-3">{t('latestSpeech')}</div>
              <AvatorScrollRow profiles={lastChatMessageProfiles} />
            </div>
            <Button
              colorScheme={'white'}
              shadow="lg"
              color={'green.900'}
              width="100%"
              onClick={() => {
                navigate(`/${projectCode?.toLowerCase()}/profiles`);
              }}
            >
              <Translation>{(t) => t('more')}</Translation>
            </Button>
            {!isSafeMode && (
              <div className="my-5">
                <PhotosWall photos={banners} isHalfLayout={true} />
              </div>
            )}
          </div>
          <div>
            <div className="px-5 py-5 shadow rounded-xl md:rounded-t-none">
              {listItems}
            </div>
          </div>
        </div>
        {memberNo && (
          <BlockBox
            idProfile={pubProfile?.idProfile}
            profile={pubProfile}
            blockCountList={blockCountList}
            targetMemberNo={memberNo}
            onBlocked={fetchBlockCount}
          />
        )}
        {pubProfile?.lastAccess && (
          <div className="text-right m-3 text-gray-400 text-sm select-none">
            {t('speeches')}：{chatMsgCount},{' '}
            {Utils().datetimeFormatter(`${pubProfile.lastAccess}+00:00`)}
          </div>
        )}
      </div>
      {Utils().isLogin() && (
        <div>
          <div className="h-[100px]"></div>
          <BottomNavigation projectCode={projectCode}></BottomNavigation>
        </div>
      )}
      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex>
              {drawerTitle}
              <Spacer></Spacer>
              <CloseButton onClick={onClose} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>{drawerListItems}</DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" onClick={onClose}>
              {t('close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
