import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Translation } from 'react-i18next'
import { Flex, Spacer, Button, Divider, CloseButton, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, DrawerFooter, ListItem, List, UnorderedList, Avatar, VStack, Center } from '@chakra-ui/react'
import { useApi } from '../../api'
import { IBanner, ICargo, IProfile } from "../../interfaces"
import { BottomNavigation } from '../../components/BottomNavigation'
import { DateTime } from "luxon";
import { t } from 'i18next';
import { Utils } from '../../utils'
import { PhotosWall } from '../../components/PhotosWall'
import { FeedbackDialog } from '../../components/FeedbackDialog'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { memberNoAtom, avatorAtom, nicknameAtom } from '../../stores/profile'
import { HotProfilesGrid } from '../../components/HotProfilesGrid'
import { CheckListItem } from '../../components/CheckListItem'
import { AvatorScrollRow } from '../../components/AvatorScrollRow'
import { HintIcon } from '../../components/HintIcon'
import { BannerReviewBlock } from '../../components/BannerReviewBlock'
import { FlameBlock } from './FlameBlock'
import { IVisitor } from '../../interfaces/IVisitor'
import { PublicCrudApi } from '../../apis/public-crud-api'
import { LastChatroomMessageBlock } from '../../components/LastChatroomMessageBlock'
import { isSafeModeAtom } from '../../stores/view'
import { LuUserCog } from 'react-icons/lu'
import { StickyNote } from '../../components/StickyNote'
import { BsDoorOpen } from 'react-icons/bs'

export const HomePage = () => {
  const navigate = useNavigate()
  const { projectCode } = useParams()
  const api = useApi(projectCode)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMounted, setIsMounted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sCoin, setSCoin] = useState<number|null>(null)
  const [mCoin, setMCoin] = useState<number|null>(null)
  const [guestbook, setGuestbook] = useState<number|null>(null)
  const [pageView, setPageView] = useState<number|null>(null)
  const [suggestionProfiles, setSuggestionProfiles] = useState<IProfile[]>([]);
  const [hotProfiles, setHotProfiles] = useState<IProfile[]>([])
  const [photoGridSampleProfiles, setPhotoGridSampleProfiles] = useState<IProfile[]>([])
  const [banners, setBanners] = useState<IBanner[]>([])
  const [unreadGuestbookCount, setUnreadGuestbookCount] = useState<number | null>(null)
  const [profile, setProfile] = useState<IProfile>()
  const [visitors, setVisitors] = useState<IVisitor[]>([])
  const [lastVisitors, setLastVisitors] = useState<IVisitor[]>([]);
  const [topVisitors, setTopVisitors] = useState<IVisitor[]>([]);

  const [newProfiles, setNewProfiles] = useState<IProfile[]>([])
  const [lastAccessProfiles, setLastAccessProfiles] = useState<IProfile[]>([])
  const [lastChatMessageProfiles, setLastChatMessageProfiles] = useState<IProfile[]>([])
  const [showReviewBlock, setReviewBlock] = useState<boolean>(true);

  const [userCount, setUserCount] = useState<number>()
  const userCountApi = PublicCrudApi('core', `members/count/${projectCode?.toUpperCase()}`)
  const [basementCount, setBasementCount] = useState<number | null>(null);
  const basementApi = PublicCrudApi('sns', 'chatroom-member/count')
  const [sevenIntroProfiles, setSevenIntroProfiles] = useState<IProfile[]>([]);
  const sevenIntroApi = PublicCrudApi(
    'core',
    `profiles/${projectCode?.toUpperCase()}/intro/seven`
  );

  const setMemberNo = useSetRecoilState(memberNoAtom);
  const setAvator = useSetRecoilState(avatorAtom);
  const setNickname = useSetRecoilState(nicknameAtom);

  const isSafeMode = useRecoilValue(isSafeModeAtom);

  useEffect(() => {
    if (profile) {
      if (profile.simpleCode) {
        setMemberNo(profile.simpleCode)
      }
      if (profile.avator) {
        setAvator(profile.avator)
      }
      if (profile.nickname) {
        setNickname(profile.nickname)
      }
    }
  }, [profile])

  const fetchProfile = useCallback(() => {
    api.getProfile((cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setProfile(cargo.info);
      }
    })
  }, [api])

  const fetchCoinInfo = useCallback(() => {
    api.getCoinInfo((cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setSCoin(cargo.info['scoin'])
        setMCoin(cargo.info['mcoin'])
      }
    })
  }, [api])

  const fetchGuestbooks = useCallback(() => {
    api.getGuestbooksCount((cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setGuestbook(cargo.info)
      }
    })
  }, [api])

  const fetchPageView = useCallback(() => {
    api.getPageView((cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setPageView(cargo.info['counter'])
      }
    })
  }, [api])

  const fetchSuggestionProfiles = useCallback(() => {
    api.getSuggestionProfiles((cargo: ICargo) => {
      if (cargo.success) {
        const profiles: IProfile[] = cargo.info;
        setSuggestionProfiles(
          profiles.filter(
            (p) => p.avator && !p.blocked && !p.blur && p.nickname && p.qualify
          )
        );
      }
    })
  }, [api])

  const fetchHotProfile = useCallback(() => {
    if (!projectCode) return;
    api.getHotProfile(projectCode, 50, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        const profiles: IProfile[] = cargo.info;
        setHotProfiles(
          profiles.filter(
            (p) => p.qualify && p.nickname && p.nickname.trim().length > 0
          )
        );
        setPhotoGridSampleProfiles(profiles.filter(p => p.avator && !p.blocked && !p.blur)
          .sort((a, b) => 0.5 - Math.random())
          .slice(1, 6))
      }
    })
  }, [api])

  const fetchBanners = useCallback(() => {
    api.getBanners((cargo: ICargo) => {
      if (cargo.success) {
        setBanners(cargo.info);
      }
    })
  }, [api])

  const fetchUnreadGuestbooks = useCallback(() => {
    api.getUnreadGuestbooks((cargo: ICargo) => {
      setUnreadGuestbookCount(cargo.info);
    })
  }, [api])

  const fetchNewProfile = useCallback(() => {
    if (!projectCode) return;
    api.getNewProfiles(projectCode, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        setNewProfiles(cargo.info);
      }
    })
  }, [api])

  const fetchLastAccessProfiles = useCallback(() => {
    if (!projectCode) return;
    api.getLastAccessProfiles(projectCode, 30, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        const profiles: IProfile[] = cargo.info;

        setLastAccessProfiles(profiles.filter(p => p.simpleCode !== window.localStorage.getItem('memberNo')));
      }
    })
  }, [api])

  const fetchLastChatMessageProfiles = useCallback(() => {
    if (!projectCode) return;
    api.getLastChatMessageProfiles(projectCode, 30, (cargo: ICargo) => {
      if (cargo.returnCode === '000000') {
        const profiles: IProfile[] = cargo.info;
        setLastChatMessageProfiles(profiles.filter(p => !p.blur));
      }
    })
  }, [api])

  const fetchVisitors = () => {
    if (visitors.length > 0) return;
    api.getVisitors((cargo: ICargo) => {
      if (cargo.returnCode === "000000") {
        setVisitors(cargo.info);
      }
    });
  }

  const fetchLastVisitors = useCallback(() => {
    api.getLastVisitors((cargo: ICargo) => {
      if (cargo.success) {
        setLastVisitors(cargo.info)
      }
    })
    api.getTopVisitors((cargo: ICargo) => {
      if (cargo.success) {
        setTopVisitors(cargo.info);
      }
    });
  }, [api])

  useEffect(() => {
    if (!isMounted) {
      fetchCoinInfo()
      fetchGuestbooks()
      fetchPageView()
      fetchSuggestionProfiles()
      fetchHotProfile()
      fetchBanners()
      fetchUnreadGuestbooks()
      fetchProfile()
      fetchNewProfile()
      fetchLastAccessProfiles()
      fetchLastChatMessageProfiles()
      fetchLastVisitors()
    }
    setIsMounted(true)
  }, [api, fetchCoinInfo, fetchGuestbooks, fetchPageView, isMounted])

  useEffect(() => {
    userCountApi.getAll().then((data: any) => setUserCount(data))
    sevenIntroApi.getAll().then((data: any) => {
      const profiles: IProfile[] = data;
      setSevenIntroProfiles(
        profiles.filter(
          (profile) =>
            !profile?.blur &&
            !profile?.blocked &&
            profile?.qualify &&
            profile.avator &&
            profile.introduction && profile.introduction?.length > 20
        )
      );
    });
    basementApi.getAll().then((data) => {
      setBasementCount(Number(data));
    })
  }, [])

  return (
    <>
      <FlameBlock />
      <div
        className="p-3 cursor-pointer"
        onClick={() => {
          navigate(`/${projectCode?.toLowerCase()}/profile`);
        }}
      >
        <div className="flex justify-end mt-3 text-gray-50 cursor-pointer">
          <div className="bg-yellow-500 py-1 px-3 rounded-full flex">
            <span className="mr-1 text-sm">{t('setupProfile')}</span>
            <LuUserCog />
          </div>
        </div>
        <List>
          <CheckListItem
            invalid={!profile?.nickname}
            content={profile?.nickname ? profile.nickname : '匿稱未設定'}
          />
          <CheckListItem
            invalid={!profile?.introduction}
            content={profile?.introduction ? t('intro') : t('introIncomplete')}
          />
          <CheckListItem
            invalid={!profile?.avator || !profile.qualify}
            content={
              profile?.avator && profile.qualify
                ? t('avatar')
                : profile?.avator
                ? t('avatarUnqualified')
                : t('avatarNotUploaded')
            }
          />
        </List>
      </div>
      {/* <div className='my-5'>
        <div className='flex flex-row gap-5'>
          <div className='basis-1/2 shadow-lg rounded-lg p-3'>
            <div className='text-left text-gray-600'>S&nbsp;
              <Translation>
                {t => (t('coin'))}
              </Translation>
            </div>
            <Divider></Divider>
            <div className='text-3xl text-right text-gray-600'>{sCoin !== null ? new Intl.NumberFormat('en', { maximumSignificantDigits: 3 }).format(sCoin) : '--'}
              <span className='text-sm ml-1'>S</span>
            </div>
          </div>
          <div className='basis-1/2 shadow-lg rounded-lg p-3'>
            <div className='text-left text-gray-600'>M&nbsp;
              <Translation>
                {t => (t('coin'))}
              </Translation>
            </div>
            <Divider></Divider>
            <div className='text-3xl text-right text-gray-600'>{mCoin !== null ? new Intl.NumberFormat('en', { maximumSignificantDigits: 3 }).format(mCoin) : '--'}
              <span className='text-sm ml-1'>M</span>
            </div>
          </div>
        </div>
      </div> */}
      <div className="my-5">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3">
          <div
            className="basis-1/2 shadow-lg rounded-lg p-3 cursor-pointer"
            onClick={() => {
              navigate(`/${projectCode}/guestbook`);
            }}
          >
            <div className="relative inline-flex w-full">
              <div className="absolute bottom-auto left-auto right-1 top-0 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap text-center align-baseline text-xs font-bold leading-none text-white">
                <span className="bg-yellow-500 px-2.5 py-1 rounded-full">
                  {unreadGuestbookCount}
                </span>
              </div>
              <div className="text-left text-gray-600">
                <Translation>{(t) => t('guestbook')}</Translation>
              </div>
            </div>
            <Divider></Divider>
            <div className="text-3xl text-right text-gray-600">
              {guestbook !== null
                ? new Intl.NumberFormat('en', {
                    maximumSignificantDigits: 3,
                  }).format(guestbook)
                : '--'}
              <span className="text-sm ml-1">G</span>
            </div>
          </div>
          <div
            className="basis-1/2 shadow-lg rounded-lg p-3 cursor-pointer"
            onClick={() => {
              onOpen();
              fetchVisitors;
            }}
          >
            <div className="text-left text-gray-600">
              <Translation>{(t) => t('visitor')}</Translation>
            </div>
            <Divider></Divider>
            <div className="text-3xl text-right text-gray-600">
              {pageView !== null
                ? new Intl.NumberFormat('en', {
                    maximumSignificantDigits: 4,
                  }).format(pageView)
                : '--'}
              <span className="text-sm ml-1">V</span>
            </div>
          </div>
          <div className="basis-1/2 shadow-lg rounded-lg p-3 relative">
            <div className="text-left text-gray-600">
              M&nbsp;
              <Translation>{(t) => t('coin')}</Translation>
            </div>
            <Divider></Divider>
            <div className="text-3xl text-right text-gray-600">
              {mCoin !== null
                ? new Intl.NumberFormat('en', {
                    maximumSignificantDigits: 4,
                  }).format(mCoin)
                : '--'}
              <span className="text-sm ml-1">M</span>
            </div>
            <div className="absolute top-2 right-2 text-gray-500">
              <HintIcon title="M幣 說明">
                <div>
                  <div>可獲得 1 M Coin</div>
                  <UnorderedList>
                    <ListItem>每收到一則貼文回覆(每則貼文只計算一次)</ListItem>
                    <ListItem>每評等一張照片(每張照片只計算一次)</ListItem>
                    <ListItem>奴的主人也都可以收到 1 M Coin</ListItem>
                  </UnorderedList>
                </div>
              </HintIcon>
            </div>
          </div>
          <div
            className="basis-1/2 shadow-lg rounded-lg p-3 cursor-pointer"
            onClick={() => {
              navigate(`/${projectCode}/s?c=${profile?.simpleCode}`);
            }}
          >
            <div className="text-left text-gray-600">{t('profilePage')}</div>
            <Divider />
            <div className="text-3xl text-right text-gray-600">Go</div>
          </div>
          {/* <div className='basis-1/2 shadow-lg rounded-lg p-3 cursor-pointer' onClick={() => {
            if (!profile?.simpleCode) return;
            navigate(`/${projectCode}/chatroom/${Utils().md5(profile?.simpleCode)}`);
          }}>
            <div className='text-left text-gray-600'>專屬聊天室</div>
            <Divider />
            <div className='text-3xl text-right text-gray-600'>
              Go
            </div>
          </div> */}
        </div>
      </div>
      {window.localStorage.getItem('token') &&
        window.localStorage.getItem('memberNo') &&
        window.localStorage.getItem('avator') &&
        window.localStorage.getItem('nickname') &&
        profile?.kycStatus !== 1 && (
          <div className="rounded-lg px-3 pb-3 pt-3 mt-3 cursor-pointer shadow-lg relative">
            <Center
              className="cursor-pointer"
              onClick={() => {
                window.location.href = '/basement/';
              }}
            >
              <BsDoorOpen size={30} color="gray" />
              <div className="ml-3 text-gray-800 select-none">
                {t('basement')}
              </div>
            </Center>
            <span className=" absolute top-1 right-2 text-gray-300 bg-slate-100 rounded-full text-xs p-1 px-2">
              {basementCount}
            </span>
          </div>
        )}
      {window.localStorage.getItem('token') &&
        window.localStorage.getItem('memberNo') &&
        window.localStorage.getItem('avator') &&
        window.localStorage.getItem('nickname') &&
        profile?.kycStatus === 1 && (
          <div className="rounded-lg px-3 pb-3 pt-3 mt-3 cursor-pointer shadow-lg relative">
            <Center
              className="cursor-pointer"
              onClick={() => {
                navigate(`/${projectCode}/ai-room`);
              }}
            >
              <BsDoorOpen size={30} color="gray" />
              <div className="ml-3 text-gray-800 select-none">
                {t('aiRoom')}
              </div>
            </Center>
          </div>
        )}
      <LastChatroomMessageBlock />
      <div className="flex flex-nowrap overflow-x-auto gap-2 pb-3 no-scrollbar">
        {suggestionProfiles.map((profile) => {
          return (
            <div
              key={profile.idProfile}
              className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 cursor-pointer"
              onClick={() => {
                navigate(`/${projectCode}/share/${profile.simpleCode}`);
              }}
            >
              <img
                className="rounded-t-lg"
                src={Utils().avatorUrlBuilder(profile)}
                alt=""
              />
              <div className="p-1 w-32">
                <p className="font-normal text-gray-700 dark:text-gray-400 text-xs line-clamp-1">
                  {profile.nickname}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="grid gap-3 grid-cols-1 md:grid-cols-2 text-gray-500">
        <div className="px-2 py-5 shadow-lg rounded-xl">
          <div>{t('latestSpeech')}</div>
          <Divider className="mb-3" />
          <AvatorScrollRow
            profiles={lastChatMessageProfiles.filter((p) => !p.blur)}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="px-2 py-5 shadow-lg rounded-xl">
            <div>{t('latestOnline')}</div>
            <Divider className="mb-3" />
            <AvatorScrollRow
              profiles={lastAccessProfiles.filter((p) => !p.blur)}
            />
          </div>
          <div className="px-2 py-5 shadow-lg rounded-xl">
            <div className="flex justify-between relative">
              <span>{t('newbie')}</span>
              <span
                className="text-xs bg-yellow-500 rounded-full px-3 py-1 text-white absolute right-0 top-[-5px] select-none cursor-pointer"
                onClick={() => {
                  navigate(`/${projectCode?.toLowerCase()}/all-profiles`);
                }}
              >
                {userCount}
              </span>
            </div>
            <Divider className="mb-3" />
            <AvatorScrollRow profiles={newProfiles} />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="px-2 py-5 shadow-lg rounded-xl">
          <div>{t('mostVisit')}</div>
          <Divider className="mb-3" />
          <AvatorScrollRow
            profiles={topVisitors.map((v) => v.profile).filter((p) => !p.blur)}
          />
        </div>
        <div className="px-2 py-5 shadow-lg rounded-xl">
          <div className="flex justify-between relative">
            <span>{t('recentVisitors')}</span>
            <span
              className="text-xs bg-yellow-500 rounded-full px-3 py-1 text-white absolute right-0 top-[-5px] select-none cursor-pointer"
              onClick={() => {
                onOpen();
                fetchVisitors();
              }}
            >
              more...
            </span>
          </div>
          <Divider className="mb-3" />
          <AvatorScrollRow profiles={lastVisitors.map((v) => v.profile)} />
        </div>
      </div>
      <div className="my-10 flex justify-end">
        <div
          className="pr-3 inline-flex items-center bg-yellow-400 border-yellow-400 border-4 rounded-full overflow-hidden whitespace-nowrap cursor-pointer"
          onClick={() => {
            navigate(`/${projectCode}/photo-grid`);
          }}
        >
          <div>
            {photoGridSampleProfiles.map((profile) => {
              return (
                <Avatar
                  key={profile.idProfile}
                  src={Utils().normalizeImagePath(
                    profile.avator,
                    profile.projectCode
                  )}
                  className="mr-1"
                  size={'xs'}
                />
              );
            })}
          </div>
          <div className="ml-1">{t('avatarWall')}</div>
        </div>
      </div>
      <HotProfilesGrid hotProfiles={hotProfiles} />
      <div className="">
        <div className="mt-10 mb-2 flex justify-end">
          <div
            className="px-3 inline-flex items-center bg-yellow-400 border-yellow-400 border-4 rounded-full overflow-hidden whitespace-nowrap cursor-pointer"
            onClick={() => {
              navigate(`/${projectCode}/profiles`);
            }}
          >
            <div>{t('hotDiscussion')}</div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
          {sevenIntroProfiles.map((profile) => {
            return (
              <StickyNote
                key={profile.simpleCode}
                profile={profile}
                content={profile.introduction}
              />
            );
          })}
        </div>
      </div>
      <div>
        <FeedbackDialog />
      </div>
      {showReviewBlock && (
        <div>
          <BannerReviewBlock
            onComplete={() => {
              setReviewBlock(false);
            }}
          />
        </div>
      )}
      <div className="my-5">
        {!isSafeMode && <PhotosWall photos={banners} />}
      </div>

      <div className="h-[100px]"></div>
      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex>
              <Translation>{(t) => t('visitor')}</Translation>
              <Spacer></Spacer>
              <CloseButton onClick={onClose} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            {visitors
              .sort(
                (a, b) =>
                  DateTime.fromISO(b.dateUpd).toUnixInteger() -
                  DateTime.fromISO(a.dateUpd).toUnixInteger()
              )
              .map((visitor) => {
                if (!visitor.nickname) return null;
                if (visitor.nickname.trim().length === 0) return null;
                return (
                  <div
                    key={visitor['simpleCode']}
                    className="mb-3 p-3 shadow-inner rounded-lg cursor-pointer"
                    onClick={() => {
                      navigate(`/${projectCode}/s?c=${visitor['simpleCode']}`);
                    }}
                  >
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <Avatar
                          size={'xs'}
                          src={Utils().normalizeImagePath(
                            visitor.profile.avator,
                            projectCode
                          )}
                        />
                        <div className="ml-2">{visitor.nickname}</div>
                      </div>
                      <Spacer></Spacer>
                      <div className="text-gray-400">
                        <div className="text-xs text-right">
                          <div>
                            {DateTime.fromISO(visitor.dateUpd).toFormat(
                              'yyyy-MM-dd'
                            )}
                          </div>
                          <div>
                            {DateTime.fromISO(visitor.dateUpd).toFormat(
                              'HH:mm:ss'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" onClick={onClose}>
              {t('close')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <BottomNavigation projectCode={projectCode}></BottomNavigation>
    </>
  );
};