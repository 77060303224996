import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useSubscribeChatroomId } from "../hooks/useSubscribeChatroomId";
import { ICargo, IChatMessage, IChatMessagePayload, IMessage, IProfile } from "../interfaces";
import { chatroomIdAtom, connectionStatusAtom, prevChatroomIdAtom, publishChatMessageAtom, publishNewNotifyAtom, receivedChatMessageAtom, receivedReloadChatroomAtom, reloadChatroomAtom } from '../stores/mqtt'
import { avatorAtom, memberNoAtom, nicknameAtom, profileAtom } from "../stores/profile";
import { Utils } from "../utils";
import { useProjectCode } from "../hooks/useProjectCode";
import { SendMessageBox } from "../components/SendMessageBox";
import { useNavigate, useParams } from "react-router-dom";
import { usePrivateApi } from "../hooks/usePrivateApi";
import { MessageBlock } from "../components/MessageBlock";
import { usePublicApi } from "../hooks/usePublicApi";
import { NaviBar } from "../components/NaviBar";
import { useToast } from "@chakra-ui/react";
import blip from '../assets/sounds/blip-131856.mp3';
import { useTranslation } from "react-i18next";

export const ChatroomPage = () => {
  const toast = useToast();
  const projectCode = useProjectCode();
  const { t } = useTranslation();

  const api = usePublicApi();
  const privateApi = usePrivateApi();
  const navigate = useNavigate();

  const [showKycOnly, setKycOnly] = useState<boolean>(window.localStorage.getItem('showKycOnly') === 'true' || false);

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [chatMessages, setChatMessages] = useState<IChatMessage[]>([]);
  const [sendMessageTo, setMessageTo] = useState<IProfile>()

  const setPrevChatroomId = useSetRecoilState(prevChatroomIdAtom);
  const setCurrentChatroomId = useSetRecoilState(chatroomIdAtom);

  const { fromMemberNo, toMemberNo, connectionId } = useParams();
  const [chatroomId, setChatroomId] = useState<string|undefined>(connectionId);
  const previousChatroomId = useSubscribeChatroomId(chatroomId || '');

  const setPublishChatMessage = useSetRecoilState(publishChatMessageAtom);
  const receivedChatMessage = useRecoilValue(receivedChatMessageAtom);
  const connectionStatus = useRecoilValue(connectionStatusAtom);
  const setReloadChatroom = useSetRecoilState(reloadChatroomAtom);
  const [receivedReloadChatroom, setReceivedReloadChatroom] = useRecoilState(receivedReloadChatroomAtom);
  const setPublishNewNotify = useSetRecoilState(publishNewNotifyAtom);

  const profile = useRecoilValue(profileAtom);
  const nickname = useRecoilValue(nicknameAtom);
  const memberNo = useRecoilValue(memberNoAtom);
  const avator = useRecoilValue(avatorAtom);

  const beep = () => {
    const snd = new Audio(blip);
    snd.play();
  };

  const rollToBottom = () => {
    if (window.localStorage.getItem('disableRollToBottom') === 'true') return;
    document.documentElement.scrollTo({
      left: 0,
      top: document.documentElement.scrollHeight - document.documentElement.clientHeight + 130,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    if (receivedReloadChatroom) {
      fetchChatroomMessages(receivedReloadChatroom, false)
      setReceivedReloadChatroom(null)
    }
  }, [receivedReloadChatroom])

  useEffect(() => {
    if (connectionStatus && chatroomId) {
      setPrevChatroomId(null);
      setCurrentChatroomId(chatroomId);
    }
  }, [chatroomId, connectionStatus, setCurrentChatroomId, setPrevChatroomId])

  useEffect(() => {
    setPrevChatroomId(previousChatroomId);
  }, [previousChatroomId, setPrevChatroomId])

  useEffect(() => {
    setTimeout(rollToBottom, 100);
    setTimeout(rollToBottom, 500);
    setTimeout(rollToBottom, 1000);
    if (receivedChatMessage?.memberNo === memberNo) return;
    if (!receivedChatMessage?.message) return;
    if (receivedChatMessage.chatroomId !== chatroomId) return;
    setChatMessages([...chatMessages, receivedChatMessage])
    setMessages([...messages, {
      idMessage: receivedChatMessage?.idMessage,
      nickname: receivedChatMessage?.nickname,
      message: receivedChatMessage?.message,
      messageType: "I",
      generateTime: new Date(),
      avator: receivedChatMessage.avator
    } as IMessage])
    window.localStorage.setItem(`last_received_chat_message_${chatroomId}`, `${Utils().getTimestamp()}`)
    beep()
  }, [receivedChatMessage])

  const fetchChatroomMessages = (chatroomId: string, rollingToBottom: boolean = true) => {
    api.getChatroomMessages(chatroomId, 200, undefined, (cargo: ICargo) => {
      const chatMsgs: IChatMessage[] = cargo.info.reverse();
      chatMsgs.forEach((msg) => {
        msg.dateAdd = new Date(msg.dateAdd+'Z');
      })
      setChatMessages(chatMsgs);
      const middleData: IMessage[] = [];
      for (let i = 0; i < cargo.info.length; ++i) {
        middleData.push(
          {
            idMessage: cargo.info[i].idChatroomMessage,
            nickname: cargo.info[i]['profile'].nickname,
            message: cargo.info[i].message,
            messageType: "I",
            generateTime: new Date(Utils().datetimeFormatter(cargo.info[i].dateAdd)),
            avator: Utils().normalizeImagePath(cargo.info[i]['profile'].avator, projectCode),
            link: `/${projectCode.toLowerCase()}/s?c=${cargo.info[i]['profile'].simpleCode}`,
            profile: cargo.info[i]['profile']
          } as IMessage
        )
        setMessages(middleData);
        if (rollToBottom) {
          setTimeout(rollToBottom, 300);
        }
      }
    })
  }

  useEffect(() => {
    if (!chatroomId) {
      if (fromMemberNo && toMemberNo) {
        privateApi.getChatroomConnection(fromMemberNo, toMemberNo, (cargo: ICargo) => {
          setChatroomId(cargo.info)
          if (cargo.success) {
            fetchChatroomMessages(cargo.info)
          }
        })
      }
    } else {
      fetchChatroomMessages(chatroomId)
    };
  }, [])

  const sendMessage = (message: string) => {
    const idMessage: string = Utils().uuid();
    if (!chatroomId) return;
    if (profile?.blocked) {
      toast({
        title: '回報封鎖',
        description: "您被檢舉次數過多，系統將自動停止使用權限。",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return;
    }
    if (!nickname && !avator && !window.localStorage.getItem('avator')) {
      toast({
        title: '溫馨小提醒',
        description: "資料完善才發言喔! 請確認匿稱及大頭貼是否都有正確呦",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      // return;
    }
    const emojiObj:IChatMessagePayload = message.includes('emoji') ? JSON.parse(message) : null;
    if (emojiObj) {
      emojiObj.type = 'emoji';
    }
    const imgObj: IChatMessagePayload = (message.includes('img') || message.includes('encodedImage')) ? JSON.parse(message) : null;
    const isPrivateConfig = window.localStorage.getItem('setIsPrivate') === 'true'
    const chatMessage: IChatMessage = {
      idMessage: idMessage,
      requestId: Utils().getUuid(),
      chatroomId: chatroomId,
      message: message,
      nickname: nickname,
      memberNo: memberNo,
      avator: avator,
      payload: emojiObj ? emojiObj : imgObj ? imgObj : {
        type: 'text',
        content: message
      },
      isPrivate: sendMessageTo ? isPrivateConfig : false,
      toProfile: sendMessageTo,
      toMemberNo: sendMessageTo?.simpleCode,
      profile: {
        nickname: nickname,
        simpleCode: memberNo,
        avator: avator
      }
    }
    setPublishChatMessage(chatMessage);
    setChatMessages([...chatMessages, chatMessage]);
    setPublishNewNotify(toMemberNo);
    setMessages([...messages, {
      idMessage: idMessage,
      message: message,
      messageType: "O",
      generateTime: new Date()
    } as IMessage])
    setTimeout(rollToBottom, 100);
    setTimeout(rollToBottom, 500);
    setTimeout(rollToBottom, 1000);
    if (toMemberNo) {
      privateApi.sendChatroomPrivateMessage(chatroomId, toMemberNo, message, JSON.stringify(chatMessage.payload), sendMessageTo ? isPrivateConfig : false, chatMessage.requestId || Utils().getUuid())
    } else if (sendMessageTo && sendMessageTo.simpleCode) {
      privateApi.sendChatroomPrivateMessage(chatroomId, sendMessageTo.simpleCode, message, JSON.stringify(chatMessage.payload), sendMessageTo ? isPrivateConfig : false, chatMessage.requestId || Utils().getUuid())
    } else {
      privateApi.sendChatroomMessage(chatroomId, message, JSON.stringify(chatMessage.payload), Utils().getUuid())
    }
  }

  return (
    <>
      <NaviBar
        title={
          fromMemberNo && toMemberNo
            ? t('privateChatroom')
            : t('personalChatroom')
        }
        memberNo={toMemberNo}
      />
      {/* {messages.map((msg, index) => {
        if (showKycOnly && msg.profile && msg.profile.kycStatus !== 1) return null;
        return (
          <MessageBlock
            key={`${index}${msg.idMessage}`}
            msg={msg}
            toAvator={Utils().normalizeImagePath(msg.avator, projectCode)}
            fromAvator={Utils().normalizeImagePath(avator, projectCode)}
            link={msg.link}
          /> 
        )
      })} */}
      {chatMessages.map((chatMessage, index) => {
        if (
          showKycOnly &&
          chatMessage.profile &&
          chatMessage.profile.kycStatus !== 1
        )
          return null;
        const message: IMessage = {
          idMessage: `${chatMessage.idChatroomMessage}`,
          nickname: chatMessage.profile?.nickname,
          message: chatMessage.message,
          messageType: 'I',
          generateTime: new Date(
            Utils().datetimeFormatter(chatMessage.dateAdd)
          ),
          avator: Utils().normalizeImagePath(
            chatMessage.profile?.avator,
            projectCode
          ),
          link: `/${projectCode.toLowerCase()}/s?c=${
            chatMessage.profile?.simpleCode
          }`,
          profile: chatMessage.profile,
        };
        return (
          <MessageBlock
            secretKey={chatroomId}
            key={`${index}${chatMessage.idMessage}`}
            msg={message}
            toAvator={Utils().normalizeImagePath(message.avator, projectCode)}
            fromAvator={Utils().normalizeImagePath(avator, projectCode)}
            link={message.link}
            chatMessage={chatMessage}
            // onClickAvator={(profile) => {
            //   // navigate(`/${projectCode.toLowerCase()}/s?c=${profile.simpleCode}`)
            // }}
            onClickAvator={setMessageTo}
            onClickNickname={setMessageTo}
            onDeleteChatMessage={(deletedChatMessage) => {
              if (
                deletedChatMessage.idChatroomMessage ||
                deletedChatMessage.requestId
              ) {
                privateApi.delChatroomMessage(
                  deletedChatMessage.idChatroomMessage ||
                    deletedChatMessage.requestId,
                  false,
                  () => {
                    setReloadChatroom(deletedChatMessage.chatroomId);
                  }
                );
                setChatMessages((chatMessages) =>
                  chatMessages.filter(
                    (chatMessage) =>
                      chatMessage.idChatroomMessage !==
                      deletedChatMessage.idChatroomMessage
                  )
                );
              }
            }}
          />
        );
      })}
      <div className="h-[130px]"></div>
      <div className="fixed left-0 bottom-0 w-full bg-white p-3">
        <SendMessageBox
          showPrivateIcon={!(fromMemberNo && toMemberNo)}
          onSend={(message) => {
            sendMessage(message);
          }}
          messageTo={sendMessageTo}
          onCancelMessageTo={() => {
            setMessageTo(undefined);
          }}
          onClickHome={(profile) => {
            navigate(`/${projectCode}/s?c=${profile.simpleCode}`);
          }}
          secretKey={chatroomId}
        />
      </div>
    </>
  );
}