import { useNavigate, useParams } from "react-router-dom";
import { IChatMessage, IProfile } from "../../interfaces";
import { Avatar, Icon, useToast } from "@chakra-ui/react";
import { Utils } from "../../utils";
import { LuEyeOff } from 'react-icons/lu';
import { MdOutlineDeleteSweep } from 'react-icons/md';
import { usePrivateApi } from "../../hooks/usePrivateApi";

interface NotificationCardProps {
  chatroomMessage: IChatMessage;
  localMemberNo: string;
  replyProfile: IProfile | null;
  onChatroomMessageDeleted?: () => void
  goChatroomUrl?: string;
}

export const NotificationCard = ({
  chatroomMessage,
  localMemberNo,
  replyProfile,
  onChatroomMessageDeleted,
  goChatroomUrl,
}: NotificationCardProps) => {
  const chatroomId = 'b3de29d2072b317fc5f8c87a5fbd9564';
  const navigate = useNavigate();
  const { projectCode } = useParams();
  const toast = useToast();
  const privateApi = usePrivateApi();

  const goChatroom = (userPath: string, chatroomId: string) => {
    navigate(goChatroomUrl ? goChatroomUrl : `/${projectCode}/chatroom${userPath}/${chatroomId}`);
  };

  const userPath =
    chatroomMessage.chatroomId === chatroomId
      ? ''
      : `/${localMemberNo}/${chatroomMessage.profile?.simpleCode}`;
  const chatMessage: IChatMessage = chatroomMessage;

  return (
    <div
      style={{ whiteSpace: 'pre-wrap' }}
      className="p-3 shadow-lg rounded-b-xl mt-[-15px] pt-5 cursor-pointer"
    >
      <div
        className="flex justify-between mt-3"
        onClick={() => {
          goChatroom(userPath, chatroomMessage.chatroomId);
        }}
      >
        <div className="flex">
          <Avatar
            src={Utils().avatorUrlBuilder(chatroomMessage.profile)}
            size={'xs'}
            className={
              replyProfile?.blur || replyProfile?.blocked
                ? 'blur-sm mb-1'
                : 'mb-1'
            }
          />
          <div className="ml-1 text-gray-600 text-sm pt-0.5">{chatMessage.profile?.nickname}</div>
        </div>
        <div
          className="text-right text-xs text-gray-400"
          onClick={() => {
            goChatroom(userPath, chatroomMessage.chatroomId);
          }}
        >
          {Utils().datetimeFormatter(`${chatroomMessage.dateUdp}Z`)}
        </div>
      </div>
      <div className="text-stone-600 max-h-28 text-ellipsis overflow-y-scroll flex justify-between">
        {chatMessage.payload && (
          <div
            className="w-full"
            onClick={() => {
              goChatroom(userPath, chatroomMessage.chatroomId);
            }}
          >
            {chatMessage.payload.type === 'text' && (
              <div>{chatMessage.payload?.content}</div>
            )}
            {chatMessage.payload.type === 'emoji' &&
              chatMessage.payload.unified && (
                <span>{chatMessage.payload.emoji}</span>
              )}
            {chatMessage.payload.type === 'img' && (
              <img
                className="flex flex-row-revers rounded-xl w-1/2 md:w-1/4 lg:w-1/6"
                src={Utils().normalizeImagePath(
                  chatMessage.payload.src,
                  projectCode
                )}
              />
            )}
          </div>
        )}
        {!chatMessage.payload && (
          <span
            onClick={() => {
              goChatroom(userPath, chatroomMessage.chatroomId);
            }}
          >
            {chatroomMessage.message}
          </span>
        )}
        {/* <div className="flex justify-end gap-3">
          <Icon
            as={MdOutlineDeleteSweep}
            fontSize={'2xl'}
            bgColor={'gray.400'}
            textColor={'white'}
            className="rounded-full p-1 cursor-pointer"
            onClick={() => {
              if (!chatroomMessage.idChatroomMessage) return;
              toast({
                title: '刪除訊息',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
              });
              privateApi.delChatroomMessage(
                chatroomMessage.idChatroomMessage,
                true,
                () => {
                  if (onChatroomMessageDeleted) {
                    onChatroomMessageDeleted();
                  }
                }
              );
            }}
          />
          <Icon
            as={LuEyeOff}
            fontSize={'2xl'}
            bgColor={'gray.400'}
            textColor={'white'}
            className="rounded-full p-1 cursor-pointer"
            onClick={() => {
              if (!chatroomMessage.idChatroomMessage) return;
              toast({
                title: '刪除訊息',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
              });
              privateApi.delChatroomMessage(
                chatroomMessage.idChatroomMessage,
                false,
                () => {
                  if (onChatroomMessageDeleted) {
                    onChatroomMessageDeleted();
                  }
                }
              );
            }}
          />
        </div> */}
      </div>
    </div>
  );
};