import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NaviBar } from '../../components/NaviBar';
import { Button, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react';
import { PublicCrudApi } from '../../apis/public-crud-api';
import { IAiImageGallery } from '../../interfaces/IAiImageGallery';

export const AiRoomPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [gallery, setGallery] = useState<IAiImageGallery[]>([])
  const [command, setCommand] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [base64Image, setBase64Image] = useState<string>();

  const imageApi = PublicCrudApi('partner', 'ai/images')

  useEffect(() => {
    imageApi.getAll<IAiImageGallery[]>().then(data => {
      setGallery(data)
    })
  }, [])

  const parseUrl = (raw: string) => {
    return raw.split('/output/')[1];
  };
  const loadImage = async (url: string) => {
    const imageResponse = await fetch(url);
    if (imageResponse.status == 200) {
      const blob = await imageResponse.blob();

      const base64String = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });

      setBase64Image(base64String);
      setProcessing(false)
    } else {
      setTimeout(() => {
        loadImage(url)
      }, 1000 * 30)
    }
  };

  return (
    <>
      <NaviBar title={t('aiRoom')} onClickMore={() => {}} />
      <div className="rounded-lg p-3 text-center border shadow mt-3">
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            placeholder="English command only"
            value={command}
            onChange={(e) => {
              setCommand(e.target.value);
            }}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              isLoading={processing}
              onClick={() => {
                if (!/^[\x00-\x7F]*$/.test(command)) {
                  toast({
                    title: 'English only',
                    description:
                      'AI training room only support English command',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top'
                  });
                  return;
                }
                setProcessing(true);
                fetch('/api/v6/public/partner/ai/image/actor/test_male', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    prompt: command,
                  }),
                })
                  .then((response) => response.json())
                  .then(async (json) => {
                    const url = parseUrl(json['info']['imageUrl']);
                    loadImage(`/output/${url}`);
                  });
              }}
            >
              Do it
            </Button>
          </InputRightElement>
        </InputGroup>

        <div className="p-3">
          {base64Image ? (
            <div className="flex justify-center items-center text-center">
              <img src={base64Image} className="rounded" />
            </div>
          ) : (
            <div className='mt-3'>
              <div>AI training room only support English command now</div>
              <div>
                The image need to take a while for generating
              </div>
              <div>Please wait around 5 mins</div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="grid grid-cols-2 md:grid-cols-6">
          {gallery.map((image) => {
            return (
              <div key={image.idImage} className='p-1'>
                <img
                  src={`/output/${parseUrl(image.imageUrl)}`}
                  className="rounded"
                />
                <div className='text-gray-500 text-sm'>{image.prompt}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
