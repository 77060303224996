import { Avatar, Button, Center, useToast } from "@chakra-ui/react"
import { ConfirmButton } from "../../components/ConfirmButton"
import { ICargo, IProfile, ISubMember } from "../../interfaces"
import { Utils } from "../../utils"
import { usePrivateApi } from "../../hooks/usePrivateApi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface SubMembersBlockProps {
  masterProfile: IProfile;
  subMembers: ISubMember[];
  onBeSubMember?: () => void;
}
export const SubMembersBlock = ({ masterProfile, subMembers, onBeSubMember }: SubMembersBlockProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const privateApi = usePrivateApi();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  return (
    <>
      <div className="my-5 p-5">
        <div className="mb-3">{t('ownedSlave')}</div>
        {subMembers?.map((subMember) => {
          return (
            <Avatar
              className="m-1 cursor-pointer"
              key={subMember.profile.idProfile}
              src={Utils().avatorUrlBuilder(subMember.profile)}
              name={subMember.profile.nickname}
              onClick={() => {
                navigate(
                  `/${subMember.profile.projectCode?.toLowerCase()}/share/${
                    subMember.profile.simpleCode
                  }`
                );
              }}
            />
          );
        })}
        {subMembers.length === 0 && (
          <Center>
            {masterProfile.nickname} {t('noSlave')}
          </Center>
        )}
      </div>
      <ConfirmButton
        isLoading={isLoading}
        onConfirm={() => {
          if (!masterProfile.simpleCode) return;
          setLoading(true);
          privateApi.beSubMember(masterProfile.simpleCode, (cargo: ICargo) => {
            setLoading(false);
            if (cargo.success) {
              if (onBeSubMember) {
                onBeSubMember();
              }
            } else {
              toast({
                title: '無法加入',
                description: '您可能目前是有主人的狀態吧，請聯絡主人讓你自由。',
                status: 'warning',
                duration: 9000,
                isClosable: true,
                position: 'top',
              });
            }
          });
        }}
        title={'請務必確認'}
        description={'加入後無法自行退出，請務必確認是否加入'}
      >
        <div>
          {(masterProfile?.multiGender === '1' ||
            masterProfile?.multiGender === '2') &&
            t('joinTheDynasty')}
          {(masterProfile?.multiGender === '3' ||
            masterProfile?.multiGender === '4') &&
            '成為奴下奴'}
          {(masterProfile?.multiGender === '5' ||
            masterProfile?.multiGender === '6' ||
            masterProfile?.multiGender === undefined) &&
            '加入'}
        </div>
      </ConfirmButton>
    </>
  );
}