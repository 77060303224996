import { useEffect, useState } from "react";
import { ICoordinates } from "../../interfaces/ICoordinates"

const getBearing = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const toRadians = (deg: number) => (deg * Math.PI) / 180;
  const toDegrees = (rad: number) => (rad * 180) / Math.PI;

  const dLon = toRadians(lon2 - lon1);
  const y = Math.sin(dLon) * Math.cos(toRadians(lat2));
  const x =
    Math.cos(toRadians(lat1)) * Math.sin(toRadians(lat2)) -
    Math.sin(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.cos(dLon);

  return (toDegrees(Math.atan2(y, x)) + 360) % 360;
};

const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Earth's radius in km
  const toRadians = (deg: number) => (deg * Math.PI) / 180;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

interface GPSArrowProps {
  userLocation: ICoordinates;
  targetLocation: ICoordinates;
}
export const GPSArrow = ({userLocation, targetLocation}: GPSArrowProps) => {
  const [bearing, setBearing] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);
  useEffect(() => {
    if (userLocation) {
      setBearing(
        getBearing(
          userLocation.latitude,
          userLocation.longitude,
          targetLocation.latitude,
          targetLocation.longitude
        )
      );
      setDistance(
        getDistance(
          userLocation.latitude,
          userLocation.longitude,
          targetLocation.latitude,
          targetLocation.longitude
        )
      );
    }
  }, [userLocation, targetLocation]);
  return (
    <>
      <div className="bg-slate-200 rounded p-1">
        <div
          style={{
            margin: '20px auto',
            width: '38px',
            height: '38px',
            borderRadius: '50%',
            border: '2px solid black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: '0',
              height: '0',
              borderLeft: '5px solid transparent',
              borderRight: '5px solid transparent',
              borderBottom: '20px solid #333',
              transform: `rotate(${bearing}deg)`,
              transition: 'transform 0.2s ease-out',
            }}
          ></div>
        </div>
      </div>
      <div className="text-sm">
        <p>Bearing: {bearing.toFixed(2)}°</p>
        <p>Distance: {distance.toFixed(2)} km</p>
      </div>
    </>
  );
}